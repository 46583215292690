/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/heading-has-content */
import React from "react";

import StaticLayout from "../components/StaticLayout";
import imgSrc1 from "../images/vierseleer3.jpg";
import imgSrc2 from "../images/opening2.jpg";
import imgSrc3 from "../images/opening.jpg";
import imgSrc4 from "../images/casino2.jpg";
import imgSrc5 from "../images/casino.jpg";
import imgSrc6 from "../images/eikenbos.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-39"
        className="post-39 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Locaties</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <h1>De Vierseleer</h1>
          <p>
            Westlaan 194
            <br />
            3550 Heusden-Zolder
          </p>
          <p>
            <img
              className="alignnone size-medium wp-image-166"
              src={imgSrc1}
              alt=""
              width={300}
              height={201}
            />
          </p>
          <p>
            De zaal&nbsp;bied ruimte voor +/- 220 personen en&nbsp;beschikt
            onder ander over:
          </p>
          <ul>
            <li>Bij binnenkomst: entreebalie en ruime garderobe</li>
            <li>Ruime&nbsp;zitgelegenheid&nbsp;met tafels en stoelen</li>
            <li>Grote dansvloer van ruime 180m²</li>
            <li>Professioneel licht- en geluidsinstallatie</li>
            <li>Gezellige bar</li>
            <li>Airconditioning</li>
            <li>2 Toiletgroepen</li>
          </ul>
          <p>
            <img
              className="alignnone size-medium wp-image-171"
              src={imgSrc2}
              alt=""
              width={300}
              height={200}
            />{" "}
            <img
              className="alignnone size-medium wp-image-170"
              src={imgSrc3}
              alt=""
              width={300}
              height={200}
            />
          </p>
          <h1 />
          <h1>Casino Houthalen</h1>
          <p style={{ textAlign: "left" }}>
            Varenstraat 22a
            <br />
            3530 Houthalen-Helchteren
          </p>
          <p>
            <img
              className="alignnone size-medium wp-image-342"
              src={imgSrc4}
              alt=""
              width={300}
              height={225}
            />{" "}
            <img
              className="alignnone size-medium wp-image-343"
              src={imgSrc5}
              alt=""
              width={300}
              height={225}
            />
          </p>
          <p>
            De zaal bied ruimte voor +/- 350 personen en beschikt onder ander
            over:
          </p>
          <ul>
            <li>Bij binnenkomst: entreebalie en ruime garderobe</li>
            <li>Ruime&nbsp;zitgelegenheid&nbsp;met tafels en stoelen</li>
            <li>Grote dansvloer van ruime 220m²</li>
            <li>Professioneel licht- en geluidsinstallatie</li>
            <li>Gezellige bar</li>
            <li>2 Toiletgroepen</li>
          </ul>
          <h1 />
          <h1>Eikenbos</h1>
          <p style={{ textAlign: "left" }}>
            Boskestraat 3
            <br />
            3560 Lummen
          </p>
          <p>
            <img
              className="alignnone size-medium wp-image-342"
              src={imgSrc6}
              alt=""
              width={300}
              height={225}
            />
          </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
